import React, { useEffect, useRef, useState } from "react";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import { HiLockClosed, HiLockOpen, HiPlay } from "react-icons/hi2";
import { Link } from "react-router-dom";
import HomeLibanoVideoPlayer from "../VideoPlayers/HomeLibanoVideoPlayer";
import HomePandaVideoPlayer from "../VideoPlayers/HomePandaVideoPlayer";
import "./VideoSlider.css";
import { certifier } from "../../mocks/certifier";
import StarRating from "../StarRating/StarRating";
import RescueCourseModal from "../Modals/RescueCourseModal";

export default function VideoClipSlide({
  availableDemoClass,
  purchaseType,
  rescueActionData,
  limitedMode,
  item,
  currentIndex,
  redirectTo,
  continueWatching,
  blankPageButton
}) {
  const [openRescuePopUp, setOpenRescuePopUp] = useState(false);
  const [isImageVisible, setImageVisibility] = useState(true);
  const [textIndex, setTextIndex] = useState(0);
  const [buttonHoverState, setButtonHoverState] = useState(false);
  const containerRef = useRef(null);
  const indexLimit = 1;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (textIndex >= indexLimit) {
        setTextIndex(0);
      } else {
        setTextIndex(textIndex + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [textIndex]);

  useEffect(() => {
    if (typeof currentIndex === "undefined") {
      const timer = setTimeout(() => {
        setImageVisibility(false);
      }, 1500);
      return;
    }
    if (currentIndex === item.id) {
      const timer = setTimeout(() => {
        setImageVisibility(false);
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      setImageVisibility(true);
    }
  }, [currentIndex, item.id]);

  useEffect(() => {
    function handleResize() {
      if (containerRef.current === undefined || containerRef.current === null)
        return;
      if (window.innerWidth <= 1024) {
        const screenHeight = window.innerHeight;
        const desiredHeight = screenHeight * 0.85;
        const desiredWidth = desiredHeight * (16 / 9);
        containerRef.current.style.height = `${desiredHeight}px`;
        containerRef.current.style.width = `${desiredWidth}px`;
        containerRef.current.style.left = "50%";
        containerRef.current.style.transform = "translateX(-50%)";
      } else {
        const desiredHeight = window.innerWidth * (9 / 16);
        containerRef.current.style.width = `${window.innerWidth}px`;
        containerRef.current.style.height = `${desiredHeight}px`;
      }
    }

    if (containerRef && limitedMode) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [limitedMode]);

  const handleClick = () => {
    window.open(item.buttonLink, "_blank");
  };

  const onHoverState = (e) => {
    setButtonHoverState(e);
  };

  return (
    <div>
      {rescueActionData && (
        <div
          className={`fixed top-0 left-0 w-full h-full z-[200] flex items-center justify-center transition-300 ${!openRescuePopUp && "opacity-0 scale-95 pointer-events-none"
            }`}>
          <RescueCourseModal
            isOpen={openRescuePopUp}
            onClose={() => setOpenRescuePopUp(false)}
            courseName={rescueActionData.name ? rescueActionData.name : "..."}
            courseAlias={rescueActionData.courseAlias}
            typeCourse={rescueActionData.typeCourse}
            getStudentData={rescueActionData.getStudentInfos}
            setIsLoading={rescueActionData.setIsLoading}
            redirectUrl={`/course/${rescueActionData.courseAlias}`}
          />
        </div>
      )}
      <div className="relative overflow-hidden">
        <div className="bg-gradient-to-t from-backgroundOne-via-72 z-[2] w-full h-full absolute top-0 left-0" />
        <div className="w-screen h-[85vh]" />
        {item.video ? (
          <>
            <img
              src={item.imageThumb}
              alt={`Conteúdo ${item.id}`}
              className={`z-[1] absolute top-0 left-0 w-full h-auto transition-all duration-500 max-lg:h-full max-lg:object-cover ${!isImageVisible && !String(item.video).includes("scorm")
                  ? "opacity-0"
                  : "opacity-100"
                }`}
            />
            {String(item.video).includes("panda") ? (
              <HomePandaVideoPlayer
                className="absolute top-0 left-0 w-full h-[calc(100vw*(9/16))] max-lg:h-[85vh] min-w-screen"
                backgroundVideo
                autoPlay
                src={item.video}
              />
            ) : String(item.video).includes("play.libanoeducacional.com.br") ? (
              <HomeLibanoVideoPlayer
                className="absolute top-0 left-0 w-full h-[calc(100vw*(9/16))] max-lg:h-[85vh] min-w-screen"
                backgroundVideo
                autoPlay
                src={item.video}
              />
            ) : (
              !String(item.video).includes("scorm") && (
                <div ref={containerRef} className="absolute top-0 left-0">
                  <video
                    ref={containerRef}
                    src={item.video}
                    autoPlay
                    muted
                    className="w-full h-full"
                  />
                </div>
              )
            )}
          </>
        ) : (
          <img
            src={item.imageThumb}
            alt={`Conteúdo ${item.id}`}
            className="absolute top-0 left-0 w-full h-auto max-lg:h-[85vh] max-lg:object-cover"
            data-swiper-parallax={currentIndex === item.id ? "0%" : "50%"}
          />
        )}
        <div
          className="absolute top-0 h-full w-[40vw]"
          data-swiper-parallax-opacity="0"
        />
        <div className="absolute top-0 left-0 text-textPrimary flex flex-col items-center justify-end h-full w-full z-[20] max-lg:px-[5vw]">
          <div className="w-full max-w-[1170px] pb-[3.75rem] max-lg:pb-[3rem]">
            <div
              className={`flex flex-col gap-y-5 ${purchaseType ? "w-[50%]" : "w-[41%]"} max-lg:w-full`}
              data-swiper-parallax-opacity="0"
              data-swiper-parallax="100%">
              <div className="flex flex-col gap-y-1 max-lg:items-center">
                {availableDemoClass && (
                  <div
                    className="flex p-3 rounded-full items-center justify-center text-sm font-semibold w-fit backdrop-blur-sm select-none cursor-not-allowed transition-300 max-lg:hidden"
                    style={{
                      // border: "1px solid rgba(199, 199, 199, 0.20)",
                      background: buttonHoverState ? "#31C67B40" : "#A8A8A840"
                    }}>
                    {buttonHoverState ? <HiLockOpen /> : <HiLockClosed />}
                  </div>
                )}
                <h1 className="my-2 text-3xl text-textPrimary font-bold leading-[1.35] max-lg:leading-tight max-lg:text-2xl max-lg:text-center">
                  {item.title}
                </h1>
                <span
                  className={`text-textPrimary text-sm max-lg:text-center ${limitedMode && "line-clamp-4"
                    } `} dangerouslySetInnerHTML={{ __html: item.description }}>
                </span>
              </div>
              {item.avaliation &&
                item.avaliation.averageStars &&
                item.avaliation.evaluationCount ? (
                <div className="flex items-center gap-3 max-lg:justify-center">
                  <span className="mt-[4px] font-medium leading-none">
                    {item.avaliation.averageStars < 4.7 ? 4.7 : item.avaliation.averageStars}
                  </span>
                  <StarRating averageStars={item.avaliation.averageStars < 4.7 ? 4.7 : item.avaliation.averageStars} />
                  <span className="mt-[4px] text-sm leading-none">•</span>
                  <span className="mt-[4px] text-sm leading-none">
                    {item.avaliation.evaluationCount.toLocaleString("pt-BR")} avaliaç
                    {item.avaliation.evaluationCount > 1 ? "ões" : "ão"}
                  </span>

                </div>
              ) : null}
              <div
                className={`flex items-center gap-6 ${availableDemoClass && "max-lg:flex-col"
                  }`}>
                {redirectTo && redirectTo !== "" ? (
                  <Link
                    className="max-lg:flex max-lg:w-full max-lg:justify-center"
                    to={`${redirectTo}`}
                    target={blankPageButton ? "_blank" : "_self"}
                    rel="noreferrer">
                    <ButtonPrimary
                      optionalBackground={
                        certifier.colors.buttons.buttonTertiary
                      }
                      optionalTextColor={
                        certifier.colors.buttons.buttonTertiary_darkTheme
                          ? "#fff"
                          : "#000"
                      }
                      textButton={`${continueWatching
                          ? "Continuar assistindo"
                          : item.buttonText
                            ? item.buttonText
                            : "Assistir agora"
                        }`}
                      desktopWidth="228px"
                      mobileWidth="60%"
                      optionalMovingIcon={!availableDemoClass && <HiPlay />}
                    />
                  </Link>
                ) : !item.testUser && (
                  <ButtonPrimary
                    optionalBackground={certifier.colors.buttons.buttonTertiary}
                    optionalTextColor={
                      certifier.colors.buttons.buttonTertiary_darkTheme
                        ? "#fff"
                        : "#000"
                    }
                    textButton={`${item.buttonText ? item.buttonText : "Assistir agora"
                      }`}
                    desktopWidth={purchaseType ? "300px" : "228px"}
                    mobileWidth="100%"
                    onHoverState={onHoverState}
                    verifyOnClick={
                      rescueActionData
                        ? () => setOpenRescuePopUp(true)
                        : item.buttonFunction
                          ? item.buttonFunction
                          : item.buttonLink
                            ? handleClick
                            : undefined
                    }
                    disabled={item.buttonDisabled}
                  />
                )}
                {availableDemoClass && !item.testUser && (
                  <ButtonPrimary
                    optionalBackground="transparent"
                    outlineType
                    outlineColor="#FFF"
                    optionalTextColor={
                      certifier.colors.buttons.buttonTertiary_darkTheme
                        ? "#000"
                        : "#fff"
                    }
                    textButton={`Assistir primeira aula`}
                    desktopWidth="227px"
                    mobileWidth="100%"
                    verifyOnClick={() =>
                      (window.location.href = `/demoCourse/${availableDemoClass.typeCourse}/${availableDemoClass.alias}${purchaseType ? "?purchaseType=true" : ""}`)
                    }
                    optionalMovingIcon={<HiPlay />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
