import "./CertificatePreview.css";
import moment from "moment";
import { brazilianStatesSelect } from "../../services/selectService/brazilianStates";

export default function CertCelsoLisboaFront({
  formData,
  selectedCourse,
  certificateContent,
}) {
  const date = new Date();
  const dateParts = date
    .toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "America/Sao_Paulo",
    })
    .split(" ");
  dateParts[2] = dateParts[2].toUpperCase();
  const actualFullDate = dateParts.join(" ");

  const formatUf = (uf) => {
    const brazilianStates = brazilianStatesSelect;

    const state = brazilianStates.find((state) => state.uf === uf);
    return state ? state.name : formData.othersBirthState;
  };

  if (formData)
    return (
      <div className="relative flex flex-col overflow-hidden">
        <img
          src="/images/assets/frontCertificado-CelsoLisboa.webp"
          alt="Frente do Certificado"
          className="w-full h-full select-none"
        />
        <div className="absolute top-0 left-0 w-full h-full">
          {/* <div className="flex flex-col pl-[5.8rem] pr-[6rem] py-[7.7rem] gap-y-[1rem]"> */}
          <div className="flex flex-col px-[18.2%] pr-[6.7%] pt-[24%] gap-y-[1.8rem]">
            <p className="text-black text-justify text-[43.88%] select-none max-mc:text-[5px]">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Reitora do Centro
              Universitário Celso Lisboa, no uso de suas atribuições que lhe
              confere o Regimento Interno, certifica que{" "}
              <b>{String(formData.name).toUpperCase()}</b>, nacionalidade{" "}
              <b>{String(formData.nationality).toUpperCase()}</b>, cédula de
              identidade n° <b>{String(formData.rg).toUpperCase()}</b>, expedido
              por{" "}
              <b>
                {String(formData.organIssuing).toUpperCase()}/
                {String(formData.uf)}
              </b>
              , nascido(a) em{" "}
              <b>
                {String(
                  moment(String(formData.birthDate)).format(
                    "DD [de] MMMM [de] YYYY"
                  )
                ).toUpperCase()}
              </b>
              , natural do Estado de{" "}
              <b>{String(formatUf(formData.uf)).toUpperCase()}</b>, concluiu o
              Curso de {String(selectedCourse.typeCourse)} <i>Lato Sensu</i>
              /Especialização em{" "}
              <b>{String(selectedCourse.label).toUpperCase()}</b> em
              conformidade com a Resolução CNE/CES Nº 1, de 6 de abril de 2018.
            </p>
            <p className="text-black text-center text-[43.88%] select-none">
              Rio de Janeiro, <b>{actualFullDate}</b>
            </p>
          </div>
        </div>
      </div>
    );
}
